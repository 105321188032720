var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-content', [_c('v-switch', {
    staticClass: "v-input--reverse",
    attrs: {
      "dense": "",
      "label": "Current Employment",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.employment.current,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "current", $$v);
      },
      expression: "employment.current"
    }
  }), _c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.incomeSourceOptions,
      "label": "Type",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.employment.income_source,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "income_source", $$v);
      },
      expression: "employment.income_source"
    }
  }), _c('v-menu', {
    ref: "startDateMenu-".concat(_vm.employmentIdx),
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '####-##-##',
            expression: "'####-##-##'"
          }],
          attrs: {
            "rules": _vm.dateStartRules,
            "label": "Start Date",
            "prepend-icon": "mdi-calendar",
            "hint": "YYYY-MM-DD",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "blur": _vm.dateStartUpdate
          },
          model: {
            value: _vm.dateDataStart,
            callback: function callback($$v) {
              _vm.dateDataStart = $$v;
            },
            expression: "dateDataStart"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.startDateMenu,
      callback: function callback($$v) {
        _vm.startDateMenu = $$v;
      },
      expression: "startDateMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "change": function change($event) {
        var _vm2;

        return (_vm2 = _vm).saveDate.apply(_vm2, ["startDateMenu-".concat(_vm.employmentIdx)].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.employment.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "start_date", $$v);
      },
      expression: "employment.start_date"
    }
  })], 1), !_vm.employment.current ? _c('v-menu', {
    ref: "endDateMenu-".concat(_vm.employmentIdx),
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '####-##-##',
            expression: "'####-##-##'"
          }],
          attrs: {
            "rules": _vm.dateEndRules,
            "label": "End Date",
            "prepend-icon": "mdi-calendar",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "blur": _vm.dateEndUpdate
          },
          model: {
            value: _vm.dateDataEnd,
            callback: function callback($$v) {
              _vm.dateDataEnd = $$v;
            },
            expression: "dateDataEnd"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2943894511),
    model: {
      value: _vm.endDateMenu,
      callback: function callback($$v) {
        _vm.endDateMenu = $$v;
      },
      expression: "endDateMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "change": function change($event) {
        var _vm3;

        return (_vm3 = _vm).saveDate.apply(_vm3, ["endDateMenu-".concat(_vm.employmentIdx)].concat(Array.prototype.slice.call(arguments)));
      }
    },
    model: {
      value: _vm.employment.end_date,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "end_date", $$v);
      },
      expression: "employment.end_date"
    }
  })], 1) : _vm._e(), _c('v-text-field', {
    attrs: {
      "dense": "",
      "type": "text",
      "placeholder": "Role",
      "label": "Role",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.employment.role,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "role", $$v);
      },
      expression: "employment.role"
    }
  }), _c('v-select', {
    attrs: {
      "dense": "",
      "items": _vm.basisOptions,
      "label": "Employment Basis",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.employment.basis,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "basis", $$v);
      },
      expression: "employment.basis"
    }
  }), _c('v-text-field', {
    attrs: {
      "dense": "",
      "type": "text",
      "placeholder": "Employer Name",
      "label": "Employer Name",
      "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
    },
    model: {
      value: _vm.employment.employer,
      callback: function callback($$v) {
        _vm.$set(_vm.employment, "employer", $$v);
      },
      expression: "employment.employer"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }