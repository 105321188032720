<template>
  <div>
    <v-list-item dense>
      <v-list-item-content>
        <v-switch
          class="v-input--reverse"
          dense
          v-model="employment.current"
          label="Current Employment"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-select
          dense
          v-model="employment.income_source"
          :items="incomeSourceOptions"
          label="Type"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-menu
          :ref="`startDateMenu-${employmentIdx}`"
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-mask="'####-##-##'"
              v-model="dateDataStart"
              :rules="dateStartRules"
              label="Start Date"
              prepend-icon="mdi-calendar"
              hint="YYYY-MM-DD"
              v-bind="attrs"
              v-on="on"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
              @blur="dateStartUpdate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="employment.start_date"
            @change="saveDate(`startDateMenu-${employmentIdx}`, ...arguments)"
          />
        </v-menu>

        <v-menu
          v-if="!employment.current"
          :ref="`endDateMenu-${employmentIdx}`"
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-mask="'####-##-##'"
              v-model="dateDataEnd"
              :rules="dateEndRules"
              label="End Date"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
              @blur="dateEndUpdate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="employment.end_date"
            @change="saveDate(`endDateMenu-${employmentIdx}`, ...arguments)"
          />
        </v-menu>

        <v-text-field
          dense
          type="text"
          v-model="employment.role"
          placeholder="Role"
          label="Role"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-select
          dense
          v-model="employment.basis"
          :items="basisOptions"
          label="Employment Basis"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />

        <v-text-field
          dense
          type="text"
          v-model="employment.employer"
          placeholder="Employer Name"
          label="Employer Name"
          :disabled="FIELDS_DISABLED && !IS_CLIENT"
        />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'EmploymentComponent',

  emits: ['onUpdateEmployment'],

  props: {
    model: {
      type: Object,
      required: true,
    },

    employmentIdx: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dateDataStart: null,

      dateDataEnd: null,

      employment: {},

      startDateMenu: null,

      endDateMenu: null,

      incomeSourceOptions: [
        'Salary/Wages',
        'Self Employed',
      ],

      basisOptions: [
        'Full Time',
        'Part Time',
        'Contract',
        'Temporary',
        'Casual',
      ],

      dateStartRules: [
        dateRequired('Start Date'),
        dateValidation('Start Date'),
      ],

      dateEndRules: [
        dateRequired('End Date'),
        dateValidation('End Date'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'IS_CLIENT']),
  },

  watch: {
    'employment.start_date': {
      handler(val) {
        this.dateDataStart = val;
      },
    },

    'employment.end_date': {
      handler(val) {
        this.dateDataEnd = val;
      },
    },

    employment: {
      handler() {
        const payload = {
          id: this.employmentIdx,
          data: this.employment,
        };

        this.$emit('onUpdateEmployment', payload);
      },

      deep: true,
    },
  },

  mounted() {
    this.employment = { ...this.model };
  },

  methods: {
    async dateStartUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.employment.start_date = this.$options.filters.dateISOFormatter(value);
      }
    },

    async dateEndUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.employment.end_date = this.$options.filters.dateISOFormatter(value);
      }
    },
    saveDate(ref, date) {
      this.$refs[ref]?.save(date);
    },
  },
};
</script>
