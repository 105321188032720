var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSubSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section,
      "clientCanEdit": _vm.IS_CLIENT
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-progress-linear', {
          staticClass: "mb-3",
          attrs: {
            "value": _vm.employmentsProgress,
            "height": "25",
            "dark": "",
            "rounded": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('strong', [_vm._v(_vm._s(parseInt(_vm.employmentsMonth, 10) - _vm.coveredMonths) + " months remain")])];
            },
            proxy: true
          }])
        }), _c('v-data-iterator', {
          key: _vm.employments.length,
          attrs: {
            "items": _vm.employments,
            "hide-default-footer": "",
            "no-data-text": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('v-row', _vm._l(_vm.employments, function (employment, employmentIdx) {
                return _c('v-col', {
                  key: employmentIdx,
                  attrs: {
                    "cols": "12",
                    "lg": "6"
                  }
                }, [_c('v-card', [_c('v-card-subtitle', [_vm._v(" #" + _vm._s(employmentIdx + 1) + " ")]), _c('v-card-text', [_c('v-list', [_c('EmploymentComponent', {
                  attrs: {
                    "model": employment,
                    "employment-idx": employmentIdx
                  },
                  on: {
                    "onUpdateEmployment": _vm.updateEmployment
                  }
                })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
                  attrs: {
                    "color": "red lighten-2",
                    "icon": "",
                    "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
                  },
                  on: {
                    "click": function click($event) {
                      $event.stopPropagation();
                      return _vm.showRemoveDialog(employmentIdx);
                    }
                  }
                }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1)], 1);
              }), 1), _c('v-dialog', {
                attrs: {
                  "max-width": "290"
                },
                model: {
                  value: _vm.isShowRemoveDialog,
                  callback: function callback($$v) {
                    _vm.isShowRemoveDialog = $$v;
                  },
                  expression: "isShowRemoveDialog"
                }
              }, [_c('v-card', [_c('v-card-title', {
                staticClass: "text-h5"
              }, [_vm._v(" Delete confirmation ")]), _c('v-card-text', [_vm._v(" Are you sure? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
                attrs: {
                  "color": "gray darken-1",
                  "text": "",
                  "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
                },
                on: {
                  "click": _vm.closeRemoveDialog
                }
              }, [_vm._v(" Cancel ")]), _c('v-btn', {
                attrs: {
                  "color": "red darken-1",
                  "text": "",
                  "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
                },
                on: {
                  "click": _vm.removeEmployment
                }
              }, [_vm._v(" Delete ")])], 1)], 1)], 1)];
            },
            proxy: true
          }])
        }), _c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "click": _vm.addEmployment
          }
        }, [_vm._v(" Add new ")])], 1)])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }