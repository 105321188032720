<template>
  <BaseSubSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    :clientCanEdit="IS_CLIENT"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-progress-linear
            class="mb-3"
            :value="employmentsProgress"
            height="25"
            dark
            rounded
          >
            <template #default>
              <strong>{{ parseInt(employmentsMonth, 10) -  coveredMonths }} months remain</strong>
            </template>
          </v-progress-linear>

          <v-data-iterator
            :items="employments"
            :key="employments.length"
            hide-default-footer
            no-data-text=""
          >
            <template #default>
              <v-row>
                <v-col
                  v-for="(employment, employmentIdx) in employments"
                  :key="employmentIdx"
                  cols="12"
                  lg="6"
                >
                  <v-card>
                    <v-card-subtitle>
                      #{{ employmentIdx + 1 }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-list>
                        <EmploymentComponent
                          :model="employment"
                          :employment-idx="employmentIdx"
                          @onUpdateEmployment="updateEmployment"
                        />
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="red lighten-2"
                        icon
                        @click.stop="showRemoveDialog(employmentIdx)"
                        :disabled="FIELDS_DISABLED && !IS_CLIENT"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <v-dialog
                v-model="isShowRemoveDialog"
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Delete confirmation
                  </v-card-title>

                  <v-card-text>
                    Are you sure?
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="gray darken-1"
                      text
                      @click="closeRemoveDialog"
                      :disabled="FIELDS_DISABLED && !IS_CLIENT"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="red darken-1"
                      text
                      @click="removeEmployment"
                      :disabled="FIELDS_DISABLED && !IS_CLIENT"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-iterator>

          <v-card-text class="mt-4">
            <div class="mb-5">
              <v-btn color="primary"
                @click="addEmployment"
                :disabled="FIELDS_DISABLED && !IS_CLIENT"
              >
                Add new
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-card-text>
    </template>
  </BaseSubSection>
</template>

<script>
import { mapGetters } from 'vuex';

import Vue from 'vue';
import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSubSection from '@/components/SubApplications/BaseSubSection.vue';

import EmploymentComponent from '@/components/SubApplications/Sections/Components/EmploymentComponent.vue';

// fixme
export default {
  name: 'EmploymentsSection',

  components: {
    BaseSubSection,
    EmploymentComponent,
  },

  mixins: [SectionMixin],

  props: {
    parentId: {
      type: String,
    },

    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialEmployments: [],

      employments: [],

      isShowRemoveDialog: false,

      removeEmploymentId: null,

      employmentsMonth: 36,
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'IS_CLIENT']),

    coveredMonths() {
      // todo
      const houres = 24;
      const minutes = 60;
      const seconds = 60;
      const milliseconds = 1000;

      const dateFormatter = houres * minutes * seconds * milliseconds;

      const finalDateEdge = new Date();
      const startDateEdge = new Date();

      const monthsDifference = 36;

      startDateEdge.setMonth(startDateEdge.getMonth() - monthsDifference);

      const coveredMonthsList = [];

      const getCoveredMonthsList = (employment) => {
        const isDateExist = Boolean(!employment.start_date !== '' && !employment.end_date !== '');

        if (isDateExist && !employment.current) {
          const startDate = new Date(employment.start_date);
          const endDate = new Date(employment.end_date);

          const startDateParse = Date.parse(startDate);
          const endDateParse = Date.parse(endDate);

          for (let date = startDateParse; date < endDateParse; date += dateFormatter) {
            const computedDate = new Date(date);

            const isEdgeSubsetFinal = computedDate <= finalDateEdge;
            const isEdgeSubsetStart = computedDate >= startDateEdge;
            const isDateEdgeSubset = Boolean(isEdgeSubsetFinal && isEdgeSubsetStart);

            if (isDateEdgeSubset) {
              const year = computedDate.getFullYear();
              const month = computedDate.getMonth();

              const dateToString = `${year}.${month}`;

              const isMonthsCovered = Boolean(coveredMonthsList.indexOf(dateToString) === -1);

              if (isMonthsCovered) {
                coveredMonthsList.push(dateToString);
              }
            }
          }
        } else if (!employment.start_date !== '' && employment.current) {
          const startDate = new Date(employment.start_date);
          const startDateParse = Date.parse(startDate);

          const endDateParse = Date.parse(finalDateEdge);

          for (let date = startDateParse; date < endDateParse; date += dateFormatter) {
            const computedDate = new Date(date);

            const isEdgeSubsetStart = Boolean(computedDate >= startDateEdge);

            if (isEdgeSubsetStart) {
              const year = computedDate.getFullYear();
              const month = computedDate.getMonth();

              const dateToString = `${year}.${month}`;

              const isMonthsCovered = Boolean(coveredMonthsList.indexOf(dateToString) === -1);

              if (isMonthsCovered) {
                coveredMonthsList.push(dateToString);
              }
            }
          }
        }
      };
      this.employments.forEach(getCoveredMonthsList);

      const coverMonthsLength = coveredMonthsList.length;

      const isFullCovered = Boolean(coverMonthsLength > this.employmentsMonth);

      return isFullCovered ? this.employmentsMonth : coverMonthsLength;
    },

    employmentsProgress() {
      return Math.round(100 * (this.coveredMonths / parseInt(this.employmentsMonth, 10)));
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialEmployments = clone(this.existedData);
    this.employments = clone(this.existedData);
  },

  methods: {
    updateEmployment(employment) {
      Vue.set(this.employments, employment.id, employment.data);
    },

    addEmployment() {
      const employmentData = {
        income_source: '',
        start_date: '',
        end_date: '',
        role: '',
        basis: '',
        employer: '',
        current: '',
      };

      this.employments.push(employmentData);
    },

    removeEmployment() {
      this.employments.splice(this.removeEmploymentId, 1);

      this.closeRemoveDialog();
    },

    showRemoveDialog(idxEmployments) {
      this.removeEmploymentId = idxEmployments;
      this.isShowRemoveDialog = true;
    },

    closeRemoveDialog() {
      this.removeEmploymentId = null;
      this.isShowRemoveDialog = false;
    },
  },
};
</script>
